import React, { useState, useEffect } from 'react';
import { Tooltip, Col, Row, Card, Alert, Button } from 'antd';
import ModalComponent from '../../components/shared/modal';
import ContentGoProShop from '../../components/shared/modal/content-modal';
import { urlWhatsApp } from '../../constants/routes';
import TusAccesoriosPeruServices from '../../services/services';
import WishListModalComponent from '../shared/modal/wishlist-modal';
import { ServicesStores } from './../../constants/constants'
import MenuComponent from '../shared/menu';
import Footer from '../shared/footer';
import { EditOutlined, WhatsAppOutlined, FacebookOutlined, PhoneOutlined } from '@ant-design/icons';
import { Progress } from "antd";
import { saveAs } from 'file-saver';

const { Meta } = Card;

const ServicesComponent = ({ reference }) => {
  const [percentDownloaded, setPercentDownloaded] = useState(0)
  const [blobGot, setBlobGot] = useState(0)
  const [progess, setProgess] = useState(0)
  const urlFile = "https://services-shop.tusaccesoriosperu.com/files/csv10000.csv";

  const getReport = () => {
    return fetch(urlFile)
  }

  const getUsers = () => {
    return fetch("https://jsonplaceholder.typicode.com/photos")
  }
const getAnother = () => {
  return fetch("https://jsonplaceholder.typicode.com/comments")
  }
 const handleClick = async () => {
  const response = await getReport()
  console.log(response)
  console.log(response.body)

  if ( !response?.body) return;

  const contentLength = response.headers.get("content-Length");
  const totalLength = typeof contentLength === "string" && parseInt(contentLength)
  console.log("totalLength", totalLength)
  const reader = response.body.getReader()
  const chunks = [];

  let receivedLength = 0;

  while (reader) {
    const { done, value } = await reader.read();

    if (done) {
      console.log("done")
      break;
    }
    chunks.push(value)
    receivedLength = receivedLength + value.length
    console.log("receivedLength", receivedLength)
    if (typeof totalLength === "number") {
      const step = receivedLength / totalLength * 100
       console.log("step", step )
       setProgess(Math.trunc(step))
    }
    // console.log("chunks", chunks)
  }


 const blob = new Blob(chunks, {type: 'text/csv;charset=utf-8'})
   console.log(blob)

    const url = URL.createObjectURL(blob)
    console.log(url)

    let a = document.createElement("a")

   a.href = url;
   a.download = "file"
   const handleDownload = () => {
    setTimeout(
      function() {
        URL.revokeObjectURL(url)
        a.removeEventListener("click", handleDownload)
      },
      150
  );
   }
   a.addEventListener("click", handleDownload, false)

   a.click()

  }

  return (
<div className="App blackramps">
        <Alert message="Espacio comercial de TUSACCESORIOS PERU" type="info" closable={false} onClick={() => window.location.href="/"} />
        <a href="tel:+51994381708" target="_blank" className="call-img">
            <img src="./images/logo-call.png" />
        </a>
        <a href="https://wa.me/51994381708" target="_blank" className="whats-img">
        <Tooltip placement="left" title={<span>En qué podemos ayudarte?</span>}>
            <img src="./images/logo-whats.png" />
      </Tooltip>
        </a>
       <div className="block">
                <div className="container">
                    <div className="document gopro">
                      <br/> <br/> <br/>
                    <div>
                      <button onClick={handleClick} download>DESCARGAR</button>
                    </div>
                    <div>
                      <button onClick={getUsers}>Users</button>
                    </div>
                    <div>
                      <button onClick={getAnother}>Comments</button>
                    </div>
                    <br/> <br/> <br/>
                    <div>
                    <Progress
                        type="dashboard"
                        steps={8}
                        percent={progess}
                        trailColor="rgba(0, 0, 0, 0.06)"
                        strokeWidth={20}
                      />
                    </div>

                    </div>
                    </div>
                    </div>
                    </div>
  )
}

export default ServicesComponent;
