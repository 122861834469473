export const AccesoriesGoPRo = [
  {
    cod: 100001,
    name: 'Arnés de pecho',
    price: 45,
    img: './images/gopro/arnes-pecho.png',
    desc: 'Pechera diseñada para deportes extremos con suma estabilidad y seguridad de filmación.'
  },
  {
    cod: 100002,
    name: 'Tuercas',
    price: 5,
    img: './images/gopro/tuercas.png',
    desc: 'Tuerca metálica con sujetador'
  },
  {
    cod: 100003,
    name: 'Sujetador con chupón',
    price: 30,
    img: './images/gopro/chupon.png',
    desc: 'Soporte para superficies como el vidrio y sus variantes. Incluye sujetador de gancho'
  },
  {
    cod: 100004,
    name: 'Gancho 360°',
    price: 30,
    img: './images/gopro/360.png',
    desc: 'Gancho de alta presión con soporte rotatorio 360°'
  },
  {
    cod: 100005,
    name: 'Mano sujetador tubular',
    price: 35,
    img: './images/gopro/mano-tubular.png',
    desc: 'Sujetador a estructura tubular con adaptador de tuerca'
  },
  {
    cod: 100006,
    name: 'Pegatinas',
    price: 6,
    img: './images/gopro/pegatinas.png',
    desc: 'Adhesivos por ambos lados para base curvo o plana'
  },
  {
    cod: 100007,
    name: 'Base plana para casco',
    price: 12,
    img: './images/gopro/base-plana.png',
    desc: 'Base perfecta para superficies planas y de alta resistencia'
  },
  {
    cod: 100008,
    name: 'Base curvo para casco',
    price: 16,
    img: './images/gopro/base-curvo.png',
    desc: 'Base perfecta para superficies curvas y de alta resistencia'
  },
  {
    cod: 100009,
    name: 'Brazo extensión corto',
    price: 12,
    img: './images/gopro/brazo-corto.png',
    desc: 'Extensión de 3.5cm aprox para articulación y manipulación de camara'
  },
  {
    cod: 100010,
    name: 'Brazo extensión largo',
    price: 15,
    img: './images/gopro/brazo-largo.png',
    desc: 'Extensión de 5cm aprox para articulación y manipulación de camara'
  },
  {
    cod: 100011,
    name: 'Gancho standar',
    price: 12,
    img: './images/gopro/gancho.png',
    desc: 'Gancho para base curvo o plana de alta resistencia'
  },
  {
    cod: 100012,
    name: 'Gancho J',
    price: 16.50,
    img: './images/gopro/gancho-J.png',
    desc: 'Gancho con extensión de brazo corto incluida'
  },
  {
    cod: 100013,
    name: 'Base redondo a tuerca',
    price: 15.50,
    img: './images/gopro/base-redondo.png',
    desc: 'Adaptador giratorio con entrada de tuerca directa.'
  },
  {
    cod: 100014,
    name: 'Sujetador de bucéo',
    price: 30,
    img: './images/gopro/buceo.png',
    desc: 'Sujetador de mano ideal para deportes acuáticos'
  },
  {
    cod: 100015,
    name: 'Palo selfie',
    price: 30,
    img: './images/gopro/selfie.png',
    desc: 'Bastón selfie de 90cm extendido, para fantásticas capturas panorámicas'
  },
  {
    cod: 100016,
    name: 'Arnés de cabeza',
    price: 30,
    img: './images/gopro/vincha.png',
    desc: 'Vincha resistente y flexible con regulador deacuerdo al diámetro de la cabeza'
  },
  {
    cod: 100017,
    name: 'Sujetador de muñeca',
    price: 24,
    img: './images/gopro/muñeca.png',
    desc: 'Soporte con engranaje para cámara y cinta para sujetar brazo o muñeca de alta resistencia'
  },
  {
    cod: 100018,
    name: 'Sujetador de timón',
    price: 28,
    img: './images/gopro/sujetador-timon.png',
    desc: 'Sujetador a tubo de medida universal y engranaje a cámara, ideal para bicicletas y motos'
  },
  {
    cod: 100019,
    name: 'Maletín porta accesorios',
    price: 50,
    img: './images/gopro/maletin.png',
    desc: 'Maleta ideal para llevar accesorios y/o cámaras'
  },
  {
    cod: 100020,
    name: 'Case normal Gopro Black 7',
    price: 100,
    img: './images/gopro/case_black_7.jpg',
    desc: 'Frame protector básico para gopro'
  },
  {
    cod: 100021,
    name: 'Case Acuático Gopro Black 7',
    price: 120,
    img: './images/gopro/case_acuatico_black_7.jpg',
    desc: 'Frame proctector actuático con botones y máxima resistencia a la presión bajo el agua. (MAX 10mts)'
  },
  {
    cod: 100022,
    name: 'Case normal Gopro Session',
    price: 100,
    img: './images/gopro/case_session_4.png',
    desc: 'Frame protector básico para gopro session'
  },
  {
    cod: 100023,
    name: 'Adaptador para tabla de surf',
    price: 65,
    img: './images/gopro/soporte_tabla.jpeg',
    desc: 'Frame protector básico para gopro session'
  },
  {
    cod: 100024,
    name: 'Soporte para la quijada del casco',
    price: 65,
    img: './images/gopro/quijada-casco.png',
    desc: 'Soporte altamente resistente, te garantizará una estabilidad y vistas espectaculares, debido a su posición estratégica'
  },
  {
    cod: 100025,
    name: 'Soporte articulado de 4 piezas para casco',
    price: 70,
    img: './images/gopro/quijada-articulado.png',
    desc: 'Accesorio compuesto por 4 brazos para una mayor manipulación de la cámara'
  },
  {
    cod: 100026,
    name: 'Case Acuático para Gopro Black 8',
    price: 120,
    img: './images/gopro/case-acuatico-gopro8.png',
    desc: 'Protector acuático para Gopro8, garantizará manipulación y protección al máximo bajo agua'
  },
  {
    cod: 100027,
    name: 'Kit completo de 48 en 1',
    price: 160,
    img: './images/gopro/48_1.png',
    desc: 'Protector acuático para Gopro8, garantizará manipulación y protección al máximo bajo agua'
  },
  {
    cod: 100028,
    name: 'Arnés para casco de ciclismo',
    price: 24,
    img: './images/gopro/arnes_casco_ciclismo.png',
    desc: 'Correa con soporte para cámara, incluye una correa adicional'
  }
];


export const BlackRampsProducts = [
  {
    cod: 100002,
    name: 'Caja de 2M',
    price: 480,
    img: './images/skateboarding/caja_vede.jpg',
    desc: 'Pechera diseñada para deportes extremos con suma estabilidad y seguridad de filmación.'
  },
  {
    cod: 100003,
    name: 'Cajón de 1.50Mx0.5M',
    price: 230,
    img: './images/skateboarding/cajon_150_x_50.jpg',
    desc: 'Pechera diseñada para deportes extremos con suma estabilidad y seguridad de filmación.'
  },
  {
    cod: 100004,
    name: 'Cajón 1.50Mx1M',
    price: 370,
    img: './images/skateboarding/cajon_150_x_100.jpg',
    desc: 'Pechera diseñada para deportes extremos con suma estabilidad y seguridad de filmación.'
  },
  {
    cod: 100005,
    name: 'Kicker 90cm',
    price: 270,
    img: './images/skateboarding/kicker_90.jpg',
    desc: 'Pechera diseñada para deportes extremos con suma estabilidad y seguridad de filmación.'
  },
   {
    cod: 100001,
    name: 'Pirámide y tubo',
    price: 590,
    img: './images/skateboarding/botador_y_tubo.jpg',
    desc: 'Pechera diseñada para deportes extremos con suma estabilidad y seguridad de filmación.'
  },
  {
    cod: 100006,
    name: 'Quarter 90',
    price: 480,
    img: './images/skateboarding/quarter_90.jpg',
    desc: 'Pechera diseñada para deportes extremos con suma estabilidad y seguridad de filmación.'
  },
  
];

export const TechnologyProducts = [
  {
    cod: 100001,
    name: 'Cargador Iphone 1M',
    price: 25,
    img: './images/tech/cargador-iphone.png',
    desc: 'Pechera diseñada para deportes extremos con suma estabilidad y seguridad de filmación.'
  },
  {
    cod: 100002,
    name: 'Soporte de gopro para estabilizador',
    price: 75,
    img: './images/tech/puluz.png',
    desc: 'Pechera diseñada para deportes extremos con suma estabilidad y seguridad de filmación.'
  },
  {
    cod: 100003,
    name: 'Xiaomi Airdots',
    price: 80,
    img: './images/tech/xiaomi-airdots.png',
    desc: 'Pechera diseñada para deportes extremos con suma estabilidad y seguridad de filmación.'
  },
  {
    cod: 100004,
    name: 'Xiaomi Smart Band 5',
    price: 135,
    img: './images/tech/xiaomi-smart.png',
    desc: 'Pechera diseñada para deportes extremos con suma estabilidad y seguridad de filmación.'
  }  
];

export const EppsProducts = [
  {
    cod: 100001,
    name: 'Guantes de nitrilo caña larga',
    price: 22,
    img: './images/epps/nitrilo.png',
    desc: 'Pechera diseñada para deportes extremos con suma estabilidad y seguridad de filmación.'
  },
  {
    cod: 100002,
    name: 'Guantes de nitron',
    price: 7,
    img: './images/epps/nitron.png',
    desc: 'Pechera diseñada para deportes extremos con suma estabilidad y seguridad de filmación.'
  },
  {
    cod: 100003,
    name: 'Guantes de badana',
    price: 4,
    img: './images/epps/badana.png',
    desc: 'Pechera diseñada para deportes extremos con suma estabilidad y seguridad de filmación.'
  },
  {
    cod: 100004,
    name: 'Respirador 6200 3M',
    price: 104,
    img: './images/epps/6200.png',
    desc: 'Pechera diseñada para deportes extremos con suma estabilidad y seguridad de filmación.'
  },
  {
    cod: 100005,
    name: 'Cartucho filtro 6003 para respirador 3M',
    price: 57,
    img: './images/epps/6003.png',
    desc: 'Pechera diseñada para deportes extremos con suma estabilidad y seguridad de filmación.'
  },
  {
    cod: 100006,
    name: 'Chaleco de seguridad poliester liviano 40gr y 60gr',
    price: 5,
    img: './images/epps/chaleco_poliester.png',
    desc: 'Pechera diseñada para deportes extremos con suma estabilidad y seguridad de filmación.'
  },
  {
    cod: 100007,
    name: 'Mamelucos descartables',
    price: 25,
    img: './images/epps/mamelucos.png',
    desc: 'Pechera diseñada para deportes extremos con suma estabilidad y seguridad de filmación.'
  },
  // {
  //   cod: 100008,
  //   name: 'cable acerado (tensor de acero) tipo BO',
  //   price: 25,
  //   img: './images/tech/cargador-iphone.png',
  //   desc: 'Pechera diseñada para deportes extremos con suma estabilidad y seguridad de filmación.'
  // },
  // {
  //   cod: 100009,
  //   name: 'templador de F° G° 5/8" pesado forjado',
  //   price: 25,
  //   img: './images/tech/cargador-iphone.png',
  //   desc: 'Pechera diseñada para deportes extremos con suma estabilidad y seguridad de filmación.'
  // },
  // {
  //   cod: 100010,
  //   name: 'templador de F° G° 1" pesado forjado',
  //   price: 25,
  //   img: './images/tech/cargador-iphone.png',
  //   desc: 'Pechera diseñada para deportes extremos con suma estabilidad y seguridad de filmación.'
  // },
  {
    cod: 100010,
    name: 'Mascarilla KN95',
    price: 2.3,
    img: './images/epps/kn95.jpg',
    desc: 'Pechera diseñada para deportes extremos con suma estabilidad y seguridad de filmación.'
  },
  {
    cod: 100011,
    name: 'pintura epoxy en spray',
    price: 48,
    img: './images/epps/epoxi.png',
    desc: 'Pechera diseñada para deportes extremos con suma estabilidad y seguridad de filmación.'
  },
  {
    cod: 100012,
    name: 'Protector facial',
    price: 2.5,
    img: './images/epps/facial.png',
    desc: 'Pechera diseñada para deportes extremos con suma estabilidad y seguridad de filmación.'
  },
  {
    cod: 100013,
    name: 'Precintos de seguridad para manometros y flujometros (MILLAR)',
    price: 710,
    img: './images/epps/precinto.png',
    desc: 'Pechera diseñada para deportes extremos con suma estabilidad y seguridad de filmación.'
  },
  // {
  //   cod: 100014,
  //   name: 'ROTULADO PERMANETE METALIZADO',
  //   price: 25,
  //   img: './images/tech/cargador-iphone.png',
  //   desc: 'Pechera diseñada para deportes extremos con suma estabilidad y seguridad de filmación.'
  // }
]

export const ServicesStores = [  
  {
    id: '000001',
    name: 'YRENE RESTAURANTE',
    slogan: 'Restaurante peruano. Streetfood sabrosa!!',
    address: 'sAv Revolución 2399, Villa el Salvador',
    phone: '927772796',
    mail: 'yrenerestauranteperu@gmail.com',
    logo: './images/services/yrene.png',
    facebook: 'https://www.facebook.com/YreneStreetfood'
  },
  {
    id: '000002',
    name: 'CHICKEN STAR',
    slogan: 'Ya con tu gusto',
    address: 'Av. Aviación , surquillo',
    phone: '937663764',
    mail: 'chickenstar@chicken.com',
    logo: './images/services/chicken_star.jpg',
    facebook: 'https://www.facebook.com/chickenstarpe'
  },
  {
    id: '000003',
    name: 'EL REY DEL SABOR',
    slogan: 'Los más deliciosos pollos a la brasa, carnes y anticuchos a la parrilla',
    address: 'sagitario, surco',
    phone: '999999999',
    mail: 'reydelsabor@correo.com',
    logo: './images/services/rey_sabor.png',
    facebook: 'https://www.facebook.com/elreydelsaboroficial'
  },
  {
    id: '000004',
    name: 'BLUE MAGIC',
    slogan: 'Venta de jeans,zapatillas,novedades lo último en moda precio al por mayor y menor',
    address: 'cerro centinela, surco',
    phone: '994366209',
    mail: 'Bluemagicperu10@gmail.com',
    logo: './images/services/blue_magic.png',
    facebook: 'https://www.facebook.com/bluemagic_oficial-104464734643423/'
  },
  {
    id: '000005',
    name: 'MACERADOS POLLITO',
    slogan: 'Venta de macerados de diversos sabores a medida de tu gusto',
    address: 'sagitario, surco',
    phone: '902546186',
    mail: 'pollito@pollito.com',
    logo: './images/services/macerados_pollito.png',
    facebook: 'https://www.facebook.com/bluemagic_oficial-104464734643423/'
  },
  {
    id: '000006',
    name: 'MOTOBOX',
    slogan: 'Tuning & Detailing De Motocicletas Multimarca',
    address: 'Av canada 3268, San Borja',
    phone: '949616594',
    mail: 'contacto@motobox.pe',
    logo: './images/services/motobox.jpg',
    facebook: 'https://www.facebook.com/motoboxperu',
    website: "https://motobox.pe/"
  }
];

// USER ADMIN
export const USER_ADMIN = {
  username: 'eddev92',
  password: 'llancaharo1'
}

export const PAY_TYPES = [
  {
    label: "Yape",
    value: 1,
  },
  {
    label: "Plin",
    value: 2,
  },
  {
    label: "Deposito en BCP",
    value: 3,
  },
  {
    label: "Deposito en BBVA",
    value: 4,
  },
  {
    label: "Efectivo",
    value: 5,
  }
]

export const DELIVERY_TYPES = [
  {
    label: "En ciudad",
    value: "1",
  },
  {
    label: "Envío nacional",
    value: "2",
  },
  {
    label: "Recojo en tienda",
    value: "3",
  }
]

export const SALES_STATES = [
  {
    label: "Concretada",
    value: 1
  },
  {
    label: "Cancelada",
    value: 2
  }
]

export const DATA_AUX = {
  "properties" : {
    "-M_M0TngsWOSWKblTwZT" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 110129,
      "information" : {
        "address" : {
          "address" : "frente a la resedincia de la embajada de brasil",
          "phone" : ""
        },
        "features" : {
          "antiquy" : {
            "type" : 1,
            "years" : 0
          },
          "bedrooms" : 3,
          "completeBathrooms" : 2,
          "desc" : "acados hace 9 nueve meses",
          "middleBathrooms" : 1,
          "parkings" : 2,
          "totalArea" : 200,
          "totalBuilded" : 200
        },
        "price" : {
          "currencyType" : 2,
          "isNegotiable" : 1,
          "price" : 385000
        }
      },
      "keyBD" : "IDPROP-M_M0TngsWOSWKblTwZT",
      "phone" : "997931130",
      "propertyType" : 3,
      "stateProperty" : 2
    },
    "-M_M1-HAmFLqrq5n7hqI" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 899577,
      "information" : {
        "address" : {
          "address" : "calle andromeda las 3 marias ",
          "phone" : ""
        },
        "features" : {
          "antiquy" : {
            "type" : 2,
            "years" : 25
          },
          "bedrooms" : 3,
          "completeBathrooms" : 2,
          "desc" : "",
          "middleBathrooms" : 1,
          "parkings" : 1,
          "totalArea" : 176,
          "totalBuilded" : 136
        },
        "price" : {
          "currencyType" : 2,
          "isNegotiable" : 1,
          "price" : 240000
        }
      },
      "keyBD" : "IDPROP-M_M1-HAmFLqrq5n7hqI",
      "phone" : "989759741",
      "propertyType" : 3,
      "stateProperty" : 2
    },
    "-M_M1EREW1OO-898Q4lW" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 380309,
      "keyBD" : "IDPROP-M_M1EREW1OO-898Q4lW",
      "phone" : "945041407",
      "propertyType" : 2,
      "stateByCall" : 1,
      "stateProperty" : 1
    },
    "-M_M1SJndOlf3Fzod2F8" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 920673,
      "keyBD" : "IDPROP-M_M1SJndOlf3Fzod2F8",
      "phone" : "999046243",
      "propertyType" : 1,
      "stateByCall" : 3,
      "stateProperty" : 1
    },
    "-M_M37ghmSrsc_d63y_n" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 632347,
      "keyBD" : "IDPROP-M_M37ghmSrsc_d63y_n",
      "phone" : "998318907",
      "propertyType" : 2,
      "stateByCall" : 1,
      "stateProperty" : 1
    },
    "-M_M3EL3E_M-FM0iaX9v" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 311877,
      "information" : {
        "address" : {
          "address" : "calle gonzalo martinez",
          "phone" : ""
        },
        "features" : {
          "antiquy" : {
            "type" : 2,
            "years" : 20
          },
          "bedrooms" : 4,
          "completeBathrooms" : 4,
          "desc" : "",
          "middleBathrooms" : 1,
          "parkings" : 1,
          "totalArea" : 637,
          "totalBuilded" : 400
        },
        "price" : {
          "currencyType" : 2,
          "isNegotiable" : 1,
          "price" : 950000
        }
      },
      "keyBD" : "IDPROP-M_M3EL3E_M-FM0iaX9v",
      "phone" : "999664475",
      "propertyType" : 2,
      "stateProperty" : 3
    },
    "-M_M3TvD_RXvsd2UIdfd" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 806441,
      "keyBD" : "IDPROP-M_M3TvD_RXvsd2UIdfd",
      "phone" : "994630021",
      "propertyType" : 2,
      "stateByCall" : 2,
      "stateProperty" : 1
    },
    "-M_M3fB6f2kT4iUO3W0U" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 259174,
      "information" : {
        "address" : {
          "address" : "diego de aguero con alonso bolibar ",
          "phone" : ""
        },
        "features" : {
          "antiquy" : {
            "type" : 2,
            "years" : 8
          },
          "bedrooms" : 4,
          "completeBathrooms" : 2,
          "desc" : "",
          "middleBathrooms" : 1,
          "parkings" : 2,
          "totalArea" : 270,
          "totalBuilded" : 270
        },
        "price" : {
          "currencyType" : 2,
          "isNegotiable" : 1,
          "price" : 300000
        }
      },
      "keyBD" : "IDPROP-M_M3fB6f2kT4iUO3W0U",
      "phone" : "994152933",
      "propertyType" : 3,
      "stateProperty" : 2
    },
    "-M_M3qDYKQL4AA_u564D" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 986149,
      "information" : {
        "address" : {
          "address" : "BUGANBILLA",
          "phone" : ""
        },
        "features" : {
          "antiquy" : {
            "type" : 2,
            "years" : 14
          },
          "bedrooms" : 3,
          "completeBathrooms" : 1,
          "desc" : "",
          "middleBathrooms" : 1,
          "parkings" : 2,
          "totalArea" : 130,
          "totalBuilded" : 130
        },
        "price" : {
          "currencyType" : 2,
          "isNegotiable" : 1,
          "price" : 225000
        }
      },
      "keyBD" : "IDPROP-M_M3qDYKQL4AA_u564D",
      "phone" : "999930739",
      "propertyType" : 3,
      "stateProperty" : 2
    },
    "-M_M3vA8Tir7uLU_B-2D" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 228962,
      "keyBD" : "IDPROP-M_M3vA8Tir7uLU_B-2D",
      "phone" : "982031045",
      "propertyType" : 3,
      "stateByCall" : 1,
      "stateProperty" : 1
    },
    "-M_M40q-T-7BMpiAjEAl" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 901112,
      "keyBD" : "IDPROP-M_M40q-T-7BMpiAjEAl",
      "phone" : "998399601",
      "propertyType" : 3,
      "stateByCall" : 1,
      "stateProperty" : 1
    },
    "-M_M4ESZf-fnObhUoBWx" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 266781,
      "information" : {
        "address" : {
          "address" : "AV POLO ",
          "phone" : ""
        },
        "features" : {
          "antiquy" : {
            "type" : 2,
            "years" : 9
          },
          "bedrooms" : 4,
          "completeBathrooms" : 3,
          "desc" : "DEPARTAMENTO ",
          "middleBathrooms" : 1,
          "parkings" : 2,
          "totalArea" : 200,
          "totalBuilded" : 200
        },
        "price" : {
          "currencyType" : 2,
          "isNegotiable" : 1,
          "price" : 370000
        }
      },
      "keyBD" : "IDPROP-M_M4ESZf-fnObhUoBWx",
      "phone" : "999046243",
      "propertyType" : 1,
      "stateProperty" : 3
    },
    "-M_M4PEo0sNPBVQYbk4K" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 489010,
      "keyBD" : "IDPROP-M_M4PEo0sNPBVQYbk4K",
      "phone" : "934282469",
      "propertyType" : 3,
      "stateByCall" : 1,
      "stateProperty" : 1
    },
    "-M_M4a_Xi-0LcaiHUv8c" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 311096,
      "keyBD" : "IDPROP-M_M4a_Xi-0LcaiHUv8c",
      "phone" : "979100108",
      "propertyType" : 3,
      "stateByCall" : 1,
      "stateProperty" : 1
    },
    "-M_M4fvh1M5BNRmQ4EC2" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 911892,
      "information" : {
        "address" : {
          "address" : "JR JACARANDA 335",
          "phone" : "99854889"
        },
        "features" : {
          "antiquy" : {
            "type" : 2,
            "years" : 2
          },
          "bedrooms" : 3,
          "completeBathrooms" : 2,
          "desc" : "POR CONFIRMAR LOS AÑOS DE ANTIGÜEDAD ",
          "middleBathrooms" : 1,
          "parkings" : 1,
          "totalArea" : 134,
          "totalBuilded" : 134
        },
        "price" : {
          "currencyType" : 2,
          "isNegotiable" : 1,
          "price" : 240000
        }
      },
      "keyBD" : "IDPROP-M_M4fvh1M5BNRmQ4EC2",
      "phone" : "947368909",
      "propertyType" : 3,
      "stateByCall" : 1,
      "stateProperty" : 2
    },
    "-M_M4kJ41rdrumCvwjgb" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 398252,
      "information" : {
        "address" : {
          "address" : "AV JACARANDA ",
          "phone" : ""
        },
        "features" : {
          "antiquy" : {
            "type" : 2,
            "years" : 25
          },
          "bedrooms" : 3,
          "completeBathrooms" : 2,
          "desc" : "",
          "middleBathrooms" : 1,
          "parkings" : 1,
          "totalArea" : 134,
          "totalBuilded" : 134
        },
        "price" : {
          "currencyType" : 2,
          "isNegotiable" : 1,
          "price" : 239000
        }
      },
      "keyBD" : "IDPROP-M_M4kJ41rdrumCvwjgb",
      "phone" : "998254889",
      "propertyType" : 3,
      "stateProperty" : 2
    },
    "-M_M4pjPf8x2oLR67hMD" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 457161,
      "keyBD" : "IDPROP-M_M4pjPf8x2oLR67hMD",
      "phone" : "955107613",
      "propertyType" : 3,
      "stateByCall" : 1,
      "stateProperty" : 1
    },
    "-M_M5-wlKFq2Sh-YazRc" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 893600,
      "information" : {
        "address" : {
          "address" : "QUIROBA ",
          "phone" : ""
        },
        "features" : {
          "antiquy" : {
            "type" : 2,
            "years" : 4
          },
          "bedrooms" : 4,
          "completeBathrooms" : 3,
          "desc" : "TRIPLEX",
          "middleBathrooms" : 1,
          "parkings" : 2,
          "totalArea" : 300,
          "totalBuilded" : 300
        },
        "price" : {
          "currencyType" : 2,
          "isNegotiable" : 2,
          "price" : 300000
        }
      },
      "keyBD" : "IDPROP-M_M5-wlKFq2Sh-YazRc",
      "phone" : "940766011",
      "propertyType" : 3,
      "stateProperty" : 3
    },
    "-M_M57NaLRjuICor2SS8" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 405728,
      "information" : {
        "address" : {
          "address" : "CDA 7 JARANDADA ",
          "phone" : ""
        },
        "features" : {
          "antiquy" : {
            "type" : 2,
            "years" : 21
          },
          "bedrooms" : 3,
          "completeBathrooms" : 2,
          "desc" : "",
          "middleBathrooms" : 1,
          "parkings" : 1,
          "totalArea" : 145,
          "totalBuilded" : 145
        },
        "price" : {
          "currencyType" : 2,
          "isNegotiable" : 1,
          "price" : 225000
        }
      },
      "keyBD" : "IDPROP-M_M57NaLRjuICor2SS8",
      "phone" : "934961926",
      "propertyType" : 3,
      "stateProperty" : 2
    },
    "-M_M5B4lEh_j4bFs_oW2" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 655066,
      "information" : {
        "address" : {
          "address" : "FRENTE AL COLEGIO INMACULADA ",
          "phone" : ""
        },
        "features" : {
          "antiquy" : {
            "type" : 2,
            "years" : 10
          },
          "bedrooms" : 3,
          "completeBathrooms" : 2,
          "desc" : "",
          "middleBathrooms" : 1,
          "parkings" : 2,
          "totalArea" : 142,
          "totalBuilded" : 115
        },
        "price" : {
          "currencyType" : 2,
          "isNegotiable" : 1,
          "price" : 240000
        }
      },
      "keyBD" : "IDPROP-M_M5B4lEh_j4bFs_oW2",
      "phone" : "999155003",
      "propertyType" : 3,
      "stateProperty" : 2
    },
    "-M_M5GNvK4oruZAetaFi" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 458818,
      "keyBD" : "IDPROP-M_M5GNvK4oruZAetaFi",
      "phone" : "991147655",
      "propertyType" : 3,
      "stateByCall" : 1,
      "stateProperty" : 1
    },
    "-M_M5SrLpJBgRWmMa-6f" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 480268,
      "keyBD" : "IDPROP-M_M5SrLpJBgRWmMa-6f",
      "phone" : "992769443",
      "propertyType" : 3,
      "stateByCall" : 1,
      "stateProperty" : 1
    },
    "-M_M5YBfWPntQbjEpwaz" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 967083,
      "information" : {
        "address" : {
          "address" : "parque los ingerios ",
          "phone" : ""
        },
        "features" : {
          "antiquy" : {
            "type" : 2,
            "years" : 10
          },
          "bedrooms" : 3,
          "completeBathrooms" : 3,
          "desc" : "",
          "middleBathrooms" : 1,
          "parkings" : 2,
          "totalArea" : 166,
          "totalBuilded" : 166
        },
        "price" : {
          "currencyType" : 2,
          "isNegotiable" : 1,
          "price" : 280000
        }
      },
      "keyBD" : "IDPROP-M_M5YBfWPntQbjEpwaz",
      "phone" : "999204342",
      "propertyType" : 3,
      "stateByCall" : 1,
      "stateProperty" : 2
    },
    "-M_M5dhX3qN4nQgVjW6M" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 862457,
      "keyBD" : "IDPROP-M_M5dhX3qN4nQgVjW6M",
      "phone" : "999636865",
      "propertyType" : 3,
      "stateByCall" : 1,
      "stateProperty" : 1
    },
    "-M_M5yW5f-JkTZGQeixg" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 715429,
      "information" : {
        "address" : {
          "address" : "calle las frisas ",
          "phone" : ""
        },
        "features" : {
          "antiquy" : {
            "type" : 2,
            "years" : 6
          },
          "bedrooms" : 3,
          "completeBathrooms" : 2,
          "desc" : "",
          "middleBathrooms" : 1,
          "parkings" : 1,
          "totalArea" : 150,
          "totalBuilded" : 150
        },
        "price" : {
          "currencyType" : 2,
          "isNegotiable" : 2,
          "price" : 249000
        }
      },
      "keyBD" : "IDPROP-M_M5yW5f-JkTZGQeixg",
      "phone" : "999286636",
      "propertyType" : 3,
      "stateProperty" : 2
    },
    "-M_M650TvY-13FapJen_" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 219265,
      "keyBD" : "IDPROP-M_M650TvY-13FapJen_",
      "phone" : "944110171",
      "propertyType" : 3,
      "stateByCall" : 1,
      "stateProperty" : 1
    },
    "-M_M6EvEfsH_Ais3MORf" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 676971,
      "keyBD" : "IDPROP-M_M6EvEfsH_Ais3MORf",
      "phone" : "954626929",
      "propertyType" : 2,
      "stateByCall" : 1,
      "stateProperty" : 1
    },
    "-M_M6Knz2GgKLuI_s4dj" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 855757,
      "keyBD" : "IDPROP-M_M6Knz2GgKLuI_s4dj",
      "phone" : "991610093",
      "propertyType" : 2,
      "stateByCall" : 1,
      "stateProperty" : 1
    },
    "-M_M6SJsOVGfRcJaY8zo" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 626806,
      "information" : {
        "address" : {
          "address" : "alonso de molina",
          "phone" : ""
        },
        "features" : {
          "antiquy" : {
            "type" : 2,
            "years" : 9
          },
          "bedrooms" : 3,
          "completeBathrooms" : 2,
          "desc" : "",
          "middleBathrooms" : 1,
          "parkings" : 2,
          "totalArea" : 249,
          "totalBuilded" : 249
        },
        "price" : {
          "currencyType" : 2,
          "isNegotiable" : 1,
          "price" : 320000
        }
      },
      "keyBD" : "IDPROP-M_M6SJsOVGfRcJaY8zo",
      "phone" : "993561059",
      "propertyType" : 3,
      "stateProperty" : 2
    },
    "-M_M6XmF-kdpc8t_lu7Q" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 330338,
      "keyBD" : "IDPROP-M_M6XmF-kdpc8t_lu7Q",
      "phone" : "999566661",
      "propertyType" : 2,
      "stateByCall" : 1,
      "stateProperty" : 1
    },
    "-M_M6jxAii0XLmHw6l3Q" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 492919,
      "keyBD" : "IDPROP-M_M6jxAii0XLmHw6l3Q",
      "phone" : "981213029",
      "propertyType" : 3,
      "stateByCall" : 2,
      "stateProperty" : 1
    },
    "-M_M6tmrbR0nLqDwouqR" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 235773,
      "information" : {
        "address" : {
          "address" : "alonso molina ",
          "phone" : ""
        },
        "features" : {
          "antiquy" : {
            "type" : 2,
            "years" : 16
          },
          "bedrooms" : 2,
          "completeBathrooms" : 2,
          "desc" : "no tiene baño medio son completos ",
          "middleBathrooms" : 1,
          "parkings" : 1,
          "totalArea" : 92,
          "totalBuilded" : 92
        },
        "price" : {
          "currencyType" : 2,
          "isNegotiable" : 1,
          "price" : 175000
        }
      },
      "keyBD" : "IDPROP-M_M6tmrbR0nLqDwouqR",
      "phone" : "977155603",
      "propertyType" : 3,
      "stateProperty" : 3
    },
    "-M_M6xhENPCrx9UVlhqs" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 566656,
      "keyBD" : "IDPROP-M_M6xhENPCrx9UVlhqs",
      "phone" : "975777990",
      "propertyType" : 3,
      "stateByCall" : 3,
      "stateProperty" : 1
    },
    "-M_M730XsRM87QJYCYVM" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 601440,
      "keyBD" : "IDPROP-M_M730XsRM87QJYCYVM",
      "phone" : "998979914",
      "propertyType" : 3,
      "stateByCall" : 1,
      "stateProperty" : 1
    },
    "-M_M76mrsS7jez6Jm1Cp" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 820299,
      "information" : {
        "address" : {
          "address" : "frente al museo de oro ",
          "phone" : ""
        },
        "features" : {
          "antiquy" : {
            "type" : 2,
            "years" : 13
          },
          "bedrooms" : 3,
          "completeBathrooms" : 2,
          "desc" : "",
          "middleBathrooms" : 1,
          "parkings" : 2,
          "totalArea" : 265,
          "totalBuilded" : 265
        },
        "price" : {
          "currencyType" : 2,
          "isNegotiable" : 1,
          "price" : 420000
        }
      },
      "keyBD" : "IDPROP-M_M76mrsS7jez6Jm1Cp",
      "phone" : "999498093",
      "propertyType" : 3,
      "stateProperty" : 3
    },
    "-M_M7s-QdFD03rMR-_tq" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 544443,
      "information" : {
        "address" : {
          "address" : "chacarilla",
          "phone" : ""
        },
        "features" : {
          "antiquy" : {
            "type" : 2,
            "years" : 11
          },
          "bedrooms" : 3,
          "completeBathrooms" : 2,
          "desc" : "",
          "middleBathrooms" : 1,
          "parkings" : 2,
          "totalArea" : 123,
          "totalBuilded" : 123
        },
        "price" : {
          "currencyType" : 2,
          "isNegotiable" : 1,
          "price" : 250000
        }
      },
      "keyBD" : "IDPROP-M_M7s-QdFD03rMR-_tq",
      "phone" : "998267192",
      "propertyType" : 3,
      "stateProperty" : 3
    },
    "-M_M87EC2lNysMZXfoon" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 942333,
      "phone" : "994912869",
      "propertyType" : 2,
      "stateProperty" : 1
    },
    "-M_M8CI0CaQbaTo4zk2V" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 50610,
      "phone" : "942434300",
      "propertyType" : 2,
      "stateProperty" : 1
    },
    "-M_M8JQ_OYpcaZ6p0ZPv" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 180321,
      "phone" : "983256145",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_M8ODQMWdWKSFwlEt4" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 713871,
      "phone" : "947889758",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_M8_rsmJHwvbJQdCl5" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 607434,
      "phone" : "996512535",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_M8dzr-_LjLQPBlmkw" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 895127,
      "phone" : "993601667",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_M8tX6j4q_0BD__LNZ" : {
      "assignedTo" : "",
      "distric" : "SAN BORJA",
      "id" : 572650,
      "phone" : "977492964",
      "propertyType" : 4,
      "stateProperty" : 1
    },
    "-M_M9-8xG3c1-qMNalYF" : {
      "assignedTo" : "",
      "distric" : "SAN BORJA",
      "id" : 334397,
      "phone" : "994069785",
      "propertyType" : 1,
      "stateProperty" : 1
    },
    "-M_M9GHtDdSilonMEQeO" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 309714,
      "phone" : "993601667",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_M9yAAP934ac6RZdp8" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 571797,
      "phone" : "998786483",
      "propertyType" : 4,
      "stateProperty" : 1
    },
    "-M_MA5YcgJNVuUve9CMq" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 889513,
      "phone" : "998307453",
      "propertyType" : 4,
      "stateProperty" : 1
    },
    "-M_MAMLEdgSvlJiJxYcF" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 483772,
      "phone" : "968470637",
      "propertyType" : 4,
      "stateProperty" : 1
    },
    "-M_MAWHOKC-sWwvKtIWK" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 503719,
      "phone" : "945978931",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MAgHn16eiqTr9J7Mq" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 926007,
      "phone" : "947296719",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MAz68H3hsQRbaQjwl" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 438506,
      "phone" : "999145554",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MB5Wjb7vtowMYuWvg" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 9368,
      "phone" : "959708148",
      "propertyType" : 2,
      "stateProperty" : 1
    },
    "-M_MBAsBqZJlyAu_sNWy" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 768691,
      "phone" : "9949540077",
      "propertyType" : 2,
      "stateProperty" : 1
    },
    "-M_MBHGjDT22VRWlX15G" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 947568,
      "phone" : "994954077",
      "propertyType" : 2,
      "stateProperty" : 1
    },
    "-M_MBNzmRldMne1rxZsH" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 637609,
      "phone" : "964170981",
      "propertyType" : 2,
      "stateProperty" : 1
    },
    "-M_MBZrbk3S76_i324RA" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 294682,
      "phone" : "992809816",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MBeIv6So6-FaU_jkn" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 67740,
      "phone" : "998162778",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MBqBurDnb6nVwTiDM" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 188201,
      "phone" : "998287627",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MBzO2kUgqb2Kimt-P" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 253696,
      "phone" : "954700233",
      "propertyType" : 2,
      "stateProperty" : 1
    },
    "-M_MC6RLOvUoOIcyiHcr" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 518426,
      "phone" : "998198395",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MCCsSk6FEvtDx7-nE" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 140587,
      "phone" : "987935353",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MCO3XX3hz-5sf0sy9" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 55557,
      "phone" : "998307811",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MCVWURDvkS7fXeF8w" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 528436,
      "phone" : "997510227",
      "propertyType" : 1,
      "stateProperty" : 1
    },
    "-M_MCmDd22ATi45REjgL" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 839322,
      "phone" : "975189955",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MDFxhyNdb34cvfRX8" : {
      "assignedTo" : "",
      "distric" : "SAN BORJA",
      "id" : 485508,
      "phone" : "958854183",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MDY5-kqU_SSMQq7Df" : {
      "assignedTo" : "",
      "distric" : "SAN BORJA",
      "id" : 28322,
      "phone" : "986876376",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MDmllaJ4pII_xg8VN" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 28300,
      "phone" : "999046675",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MDxdSeFvSp8tjWgfH" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 126175,
      "phone" : "924795950",
      "propertyType" : 2,
      "stateProperty" : 1
    },
    "-M_ME6AGoW7imJbnoZkQ" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 783537,
      "phone" : "971771735",
      "propertyType" : 1,
      "stateProperty" : 1
    },
    "-M_MEDRc7EvaUvgNoEj0" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 351299,
      "phone" : "954128609",
      "propertyType" : 2,
      "stateProperty" : 1
    },
    "-M_MERWVPMNPtAaEI4yG" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 222641,
      "phone" : "985793444",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MEadOJ30CozYSbxQE" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 949441,
      "phone" : "999043266",
      "propertyType" : 1,
      "stateProperty" : 1
    },
    "-M_MF546_2Sux4K0H8CT" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 363806,
      "phone" : "956074566",
      "propertyType" : 1,
      "stateProperty" : 1
    },
    "-M_MFG8a92r0OQiigj0w" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 456839,
      "phone" : "013080570",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MFPIpMcpmRUXEvoUO" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 968412,
      "phone" : "999723432",
      "propertyType" : 1,
      "stateProperty" : 1
    },
    "-M_MFWshtk3LntY0lNGY" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 761115,
      "phone" : "942094185",
      "propertyType" : 1,
      "stateProperty" : 1
    },
    "-M_MGJ-BN-KoSAHANkcI" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 84356,
      "phone" : "998333972",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MGWce-tRfdluVPfV3" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 611876,
      "phone" : "999380874",
      "propertyType" : 2,
      "stateProperty" : 1
    },
    "-M_MGdKcKi6IY3jQU6FC" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 802958,
      "phone" : "996801565",
      "propertyType" : 2,
      "stateProperty" : 1
    },
    "-M_MGlRWcK-VbHYi-iC5" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 646626,
      "phone" : "980503728",
      "propertyType" : 2,
      "stateProperty" : 1
    },
    "-M_MGu_C8Fgqt5kzRCjz" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 525924,
      "phone" : "994405854",
      "propertyType" : 1,
      "stateProperty" : 1
    },
    "-M_MH0e7s6G8Ryf8QA1X" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 290428,
      "phone" : "949326369",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MH5l1lkVmA_qSDkm_" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 744685,
      "phone" : "964233399",
      "propertyType" : 2,
      "stateProperty" : 1
    },
    "-M_MHA67M8bwSjmkdcs7" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 330592,
      "phone" : "997597963",
      "propertyType" : 2,
      "stateProperty" : 1
    },
    "-M_MHGzxR771y_428sGt" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 330225,
      "phone" : "973589923",
      "propertyType" : 2,
      "stateProperty" : 1
    },
    "-M_MHXSBot0OPFSD5EKv" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 782786,
      "phone" : "999874130",
      "propertyType" : 1,
      "stateProperty" : 1
    },
    "-M_MHxUqe0B4QdeviLUd" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 949719,
      "phone" : "999095280",
      "propertyType" : 1,
      "stateProperty" : 1
    },
    "-M_MI1Aq6lPvH5MRq80A" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 798862,
      "phone" : "991495010",
      "propertyType" : 2,
      "stateProperty" : 1
    },
    "-M_MIPJsrFXdEsmPEKn0" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 352792,
      "phone" : "964024796",
      "propertyType" : 2,
      "stateProperty" : 1
    },
    "-M_MITxDnZaPB-sWDG8s" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 872979,
      "phone" : "948148230",
      "propertyType" : 2,
      "stateProperty" : 1
    },
    "-M_MIaZZsO82lhnoyIr6" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 441898,
      "phone" : "999274044",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MIiUaZe6bAx4vooDo" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 823671,
      "phone" : "988927396",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MIvOe8lIXZ_-q_0Mp" : {
      "assignedTo" : "",
      "distric" : "SAN BORJA",
      "id" : 142115,
      "phone" : "946074748",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MJ7__QZjiAtCw3mbM" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 491927,
      "phone" : "992823336",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MJDFhUqW4uEYu4OqA" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 245994,
      "phone" : "961702701",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MJbEoW8FMNakVE_Ry" : {
      "assignedTo" : "",
      "distric" : "SAN BORJA",
      "id" : 501749,
      "phone" : "946020672",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MJiTvh7QujYIDYjCQ" : {
      "assignedTo" : "",
      "distric" : "SAN BORJA",
      "id" : 585125,
      "phone" : "999922530",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MJsyMs8FA6GZK0gKK" : {
      "assignedTo" : "",
      "distric" : "SAN BORJA",
      "id" : 753260,
      "phone" : "983488874",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MK4r5Uq-VfJAzxrMR" : {
      "assignedTo" : "",
      "distric" : "SAN BORJA",
      "id" : 370743,
      "phone" : "987780955",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MKKOwJI0CGuhQCWJ8" : {
      "assignedTo" : "",
      "distric" : "SAN BORJA",
      "id" : 572478,
      "phone" : "997085458",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MKaPNbKinioLSYG5q" : {
      "assignedTo" : "",
      "distric" : "SAN BORJA",
      "id" : 251588,
      "phone" : "972973450",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MKigsfBtbPe0BnY53" : {
      "assignedTo" : "",
      "distric" : "SAN BORJA",
      "id" : 491596,
      "phone" : "972973450",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MKxs_T-5qxMbnkTv1" : {
      "assignedTo" : "",
      "distric" : "SAN BORJA",
      "id" : 916830,
      "phone" : "972973450",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_ML2Nr50Kan5bWt9c0" : {
      "assignedTo" : "",
      "distric" : "SAN BORJA",
      "id" : 591539,
      "phone" : "994266479",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_ML9-kzgjX5jpnN3Mi" : {
      "assignedTo" : "",
      "distric" : "SAN BORJA",
      "id" : 267080,
      "phone" : "999286633",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MLHSQ3JlJQkMgiyg1" : {
      "assignedTo" : "",
      "distric" : "SAN BORJA",
      "id" : 590368,
      "phone" : "950040154",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MLVmHJgRKpWZX1D_7" : {
      "assignedTo" : "",
      "distric" : "SAN BORJA",
      "id" : 463770,
      "phone" : "999467979",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MLaURkrScX0sBvbVo" : {
      "assignedTo" : "",
      "distric" : "SAN BORJA",
      "id" : 478956,
      "phone" : "991406663",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MLfs22tjvclsJ5NXD" : {
      "assignedTo" : "",
      "distric" : "SAN BORJA",
      "id" : 666091,
      "phone" : "998490874",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MLoVtV8u1bKS-O2St" : {
      "assignedTo" : "",
      "distric" : "SAN BORJA",
      "id" : 216135,
      "phone" : "922257587",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MM1TQkjtfqxlnug1S" : {
      "assignedTo" : "",
      "distric" : "SAN BORJA",
      "id" : 339001,
      "phone" : "999401299",
      "propertyType" : 2,
      "stateProperty" : 1
    },
    "-M_MMDGanO4NbDHtX_uH" : {
      "assignedTo" : "",
      "distric" : "SAN BORJA",
      "id" : 679944,
      "phone" : "967805502",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MMMgqzis1dwZNz9ff" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 728793,
      "phone" : "999410003",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MMYIWrZYMLP5zZns0" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 882878,
      "phone" : "969365584",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MMczrDm8ncfTkOHWL" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 244606,
      "phone" : "994157851",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MMniqiuiMOhEWr59u" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 228177,
      "phone" : "982889027",
      "propertyType" : 2,
      "stateProperty" : 1
    },
    "-M_MMwnEnczbyWGvev9t" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 978838,
      "phone" : "954852488",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MN5vq6M9gm0HVXS7R" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 285402,
      "phone" : "945024060",
      "propertyType" : 2,
      "stateProperty" : 1
    },
    "-M_MNCYu3L33ujwA3NBJ" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 75792,
      "phone" : "999855202",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MNRWSm7j51uj5BJn4" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 832897,
      "phone" : "940756980",
      "propertyType" : 2,
      "stateProperty" : 1
    },
    "-M_MNWxojUD6rLS-2vVs" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 617606,
      "phone" : "980620735",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MNiB3xk4-oBMyFweV" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 351215,
      "phone" : "999310284",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MNufSzeDDGnvVQL02" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 719389,
      "phone" : "994175674",
      "propertyType" : 2,
      "stateProperty" : 1
    },
    "-M_MO0DaSrxPnQH-Qih7" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 510014,
      "phone" : "945701143",
      "propertyType" : 2,
      "stateProperty" : 1
    },
    "-M_MO9kpu8C1T-W0MEqD" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 346281,
      "phone" : "985598971",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MOHEbANsDMiZJYmV0" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 209050,
      "phone" : "994047289",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MON4qYcmDU_cGFN9m" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 63899,
      "phone" : "999094510",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MOVoMSm2iFOACDyAz" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 514827,
      "phone" : "994175674",
      "propertyType" : 1,
      "stateProperty" : 1
    },
    "-M_MOlFHmJerWFEPV4Ts" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 207390,
      "phone" : "999281409",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MOsaY3ZOGQTG0x4v7" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 988617,
      "phone" : "992703554",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MP8--C96dhsM4lfaH" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 92005,
      "phone" : "998887011",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MPESv8cksccOaXeVk" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 399107,
      "phone" : "999206000",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MPLyRfQjoax7acHKB" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 717444,
      "phone" : "994629463",
      "propertyType" : 4,
      "stateProperty" : 1
    },
    "-M_MPV2lZfsfCPdCo30J" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 747750,
      "phone" : "944604059",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MPeAbGIVgIb5S6m2j" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 977201,
      "phone" : "986569705",
      "propertyType" : 2,
      "stateProperty" : 1
    },
    "-M_MPq0vmXL55IGsseC4" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 967882,
      "phone" : "992843145",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MQ9Etc7FRHlUANfbH" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 291125,
      "phone" : "951239111",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MQO3p8KgtRfEsYBob" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 331015,
      "phone" : "998536418",
      "propertyType" : 1,
      "stateProperty" : 1
    },
    "-M_MQZpdn_lSg5U10AOE" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 789561,
      "phone" : "994018350",
      "propertyType" : 4,
      "stateProperty" : 1
    },
    "-M_MQfTTGKy9MeMXANSH" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 766786,
      "phone" : "992620963",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MQr2IAgFpmtQrgGhr" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 769256,
      "phone" : "943874135",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MQyQFn-8e-kAPXEf9" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 177749,
      "phone" : "987495640",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MR2KUjo7J44peBzLo" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 259027,
      "phone" : "946056119",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MR8nuydusWySTNkKX" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 969179,
      "phone" : "997506760",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MRO5gjx4xrhk_20kt" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 980569,
      "phone" : "998441910",
      "propertyType" : 2,
      "stateProperty" : 1
    },
    "-M_MSGBeCsJquSIVIyWY" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 857184,
      "phone" : "993580931",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MSMmBuDcCfcHLXYUE" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 547275,
      "phone" : "973968494",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MSRxZw0aItGkUiCxn" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 76583,
      "phone" : "999062660",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MSXpTFcliPGDybBiH" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 774928,
      "phone" : "955706983",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MSkHj6zNEZqpHUY0w" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 416859,
      "phone" : "989045759",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MSu87HFdNSrbUCWQh" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 963009,
      "phone" : "965393901",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MT033sZyuia5ZxCg4" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 361683,
      "phone" : "989088339",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MTpD4OlzTo2wVNB_-" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 446850,
      "phone" : "972286322",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MTsv9p9tiizjUpSz-" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 692628,
      "phone" : "999214502",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MU2NsabcPvTS9HCO9" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 292106,
      "phone" : "999214502",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MU8zUimUa3ww16Lt_" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 752250,
      "phone" : "956282420",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MUMkjQd7R0egQkalV" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 334325,
      "phone" : "944656387",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MURbXzjcNxwf5QXZ9" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 104220,
      "phone" : "995747900",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MUcwRlWvZRi_994qu" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 264784,
      "phone" : "994680891",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MUhGC9TRWelcmMmsM" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 371072,
      "phone" : "996300844",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MUoSD5yu8DZRVg4Xj" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 137207,
      "phone" : "947118158",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MV4kzQwxtd1OBT-Oq" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 268961,
      "phone" : "951547126",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MVF8eUVZDSIJhyDCe" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 601315,
      "phone" : "941365330",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MVYO9PMjxAk9USNsv" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 834917,
      "phone" : "012658733",
      "propertyType" : 2,
      "stateProperty" : 1
    },
    "-M_MVgbhBkPUdwELJf0v" : {
      "assignedTo" : "",
      "distric" : "MIRAFLORES",
      "id" : 698410,
      "phone" : "996596218",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MVowaj0CcVGRBcgRQ" : {
      "assignedTo" : "",
      "distric" : "MIRAFLORES",
      "id" : 107963,
      "phone" : "943973281",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MVxNZ2uNz9UBcHsZm" : {
      "assignedTo" : "",
      "distric" : "MIRAFLORES",
      "id" : 347958,
      "phone" : "975527177",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MW1WzuKb-DE0R1OyO" : {
      "assignedTo" : "",
      "distric" : "MIRAFLORES",
      "id" : 99646,
      "phone" : "989173550",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MWDcjUiSsZl8ZIYvz" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 175684,
      "phone" : "998438781",
      "propertyType" : 4,
      "stateProperty" : 1
    },
    "-M_MWKRlNesP9YgkOlhq" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 533218,
      "phone" : "998460773",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MWS4BzkKNb1JoIcOn" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 669184,
      "phone" : "941365330",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MW_zIujz5S1SCTF0v" : {
      "assignedTo" : "",
      "distric" : "SAN ISIDRO",
      "id" : 977159,
      "phone" : "981468671",
      "propertyType" : 2,
      "stateProperty" : 1
    },
    "-M_MWtWJbawVpSgoT05i" : {
      "assignedTo" : "",
      "distric" : "MIRAFLORES",
      "id" : 390991,
      "phone" : "994092042",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MX84zNzs6I95A9P-i" : {
      "assignedTo" : "",
      "distric" : "MIRAFLORES",
      "id" : 868585,
      "phone" : "956365205",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MXJ0drQOzju46Nw4_" : {
      "assignedTo" : "",
      "distric" : "MIRAFLORES",
      "id" : 528120,
      "phone" : "998221087",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MXWkIW2_C9g53wqqu" : {
      "assignedTo" : "",
      "distric" : "MIRAFLORES",
      "id" : 783769,
      "phone" : "999923143",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MXdbkeSoGyD1yyrj1" : {
      "assignedTo" : "",
      "distric" : "MIRAFLORES",
      "id" : 568508,
      "phone" : "998330334",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MXkQ9uZZ1_iTHgRBd" : {
      "assignedTo" : "",
      "distric" : "MIRAFLORES",
      "id" : 704116,
      "phone" : "971109547",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MXyTfgi1Ve68EImtz" : {
      "assignedTo" : "",
      "distric" : "MIRAFLORES",
      "id" : 729608,
      "phone" : "981426597",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MYK3biAIXSaAitZv4" : {
      "assignedTo" : "",
      "distric" : "MIRAFLORES",
      "id" : 380764,
      "phone" : "963818594",
      "propertyType" : 2,
      "stateProperty" : 1
    },
    "-M_MYlUIQW7nYar0JyTj" : {
      "assignedTo" : "",
      "distric" : "MIRAFLORES",
      "id" : 9858,
      "phone" : "997915720",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MZEZdR-9cC6QqfplB" : {
      "assignedTo" : "",
      "distric" : "MIRAFLORES",
      "id" : 16434,
      "phone" : "969335881",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MZb7cvQeGdHxuSiJx" : {
      "assignedTo" : "",
      "distric" : "MIRAFLORES",
      "id" : 348142,
      "phone" : "935556904",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MZkArsAuBwCdbD1Gz" : {
      "assignedTo" : "",
      "distric" : "MIRAFLORES",
      "id" : 753929,
      "phone" : "999185632",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_M_EULI7pOMt1_DEcv" : {
      "assignedTo" : "",
      "distric" : "MIRAFLORES",
      "id" : 426091,
      "phone" : "936839235",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_M_LVNfCc-Js_ezain" : {
      "assignedTo" : "",
      "distric" : "MIRAFLORES",
      "id" : 536292,
      "phone" : "999356150",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_M_kRdUTtRlcp7mxP8" : {
      "assignedTo" : "",
      "distric" : "MIRAFLORES",
      "id" : 552778,
      "phone" : "981559392",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_M_yiXYh8ue6vx_VY6" : {
      "assignedTo" : "",
      "distric" : "MIRAFLORES",
      "id" : 361409,
      "phone" : "995519976",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_Maw5pE-zRGhAbD2kD" : {
      "assignedTo" : "",
      "distric" : "MIRAFLORES",
      "id" : 812650,
      "phone" : "997514819",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MdEcvM9zHdzz4PdcJ" : {
      "assignedTo" : "",
      "distric" : "MIRAFLORES",
      "id" : 452722,
      "phone" : "994091607",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MdUsCEjq3fwmVgokK" : {
      "assignedTo" : "",
      "distric" : "MIRAFLORES",
      "id" : 724077,
      "phone" : "999432690",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MdZouSFX9N2JLBu2C" : {
      "assignedTo" : "",
      "distric" : "MIRAFLORES",
      "id" : 420448,
      "phone" : "941361836",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MdeItewAjpC3FYGfy" : {
      "assignedTo" : "",
      "distric" : "MIRAFLORES",
      "id" : 218472,
      "phone" : "924274821",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MdozaHwltK0EyB8rq" : {
      "assignedTo" : "",
      "distric" : "MIRAFLORES",
      "id" : 713513,
      "phone" : "9812066965",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MduXT9F8RXDh5K10a" : {
      "assignedTo" : "",
      "distric" : "MIRAFLORES",
      "id" : 190282,
      "phone" : "999745777",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_Me3U6Exw-GKDv4D75" : {
      "assignedTo" : "",
      "distric" : "MIRAFLORES",
      "id" : 890200,
      "phone" : "994708791",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MeBjoJ0d-wkys9i7p" : {
      "assignedTo" : "",
      "distric" : "MIRAFLORES",
      "id" : 234400,
      "phone" : "945822646",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MeOhp0MZrvGmXYOdH" : {
      "assignedTo" : "",
      "distric" : "MIRAFLORES",
      "id" : 260997,
      "phone" : "999656867",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_MeWNGABubl6Wm9i16" : {
      "assignedTo" : "",
      "distric" : "MIRAFLORES",
      "id" : 462328,
      "phone" : "988802140",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_N-B6i35X3nfuAUrDf" : {
      "assignedTo" : "",
      "distric" : "MIRAFLORES",
      "id" : 80805,
      "phone" : "994682601",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_N-QwyYGxJv6XBlme-" : {
      "assignedTo" : "",
      "distric" : "MIRAFLORES",
      "id" : 423521,
      "phone" : "985494848",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_N0F_eZ80cBPX_3ztf" : {
      "assignedTo" : "",
      "distric" : "MIRAFLORES",
      "id" : 943841,
      "phone" : "949720558",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_N0PAHWL1AnCgjlWWw" : {
      "assignedTo" : "",
      "distric" : "MIRAFLORES",
      "id" : 275135,
      "phone" : "974317007",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_N0XKfI4KcNi3CzyFl" : {
      "assignedTo" : "",
      "distric" : "MIRAFLORES",
      "id" : 92969,
      "phone" : "934584796",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_N0feYB5SbTCpfYj7q" : {
      "assignedTo" : "",
      "distric" : "MIRAFLORES",
      "id" : 340824,
      "phone" : "950113164",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_N0sO08PXsxqVgU-nS" : {
      "assignedTo" : "",
      "distric" : "MIRAFLORES",
      "id" : 392781,
      "phone" : "935556904",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_N1lFE-sYR3n_vdf40" : {
      "assignedTo" : "",
      "distric" : "MIRAFLORES",
      "id" : 912122,
      "phone" : "998301829",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_N1r7q3eZrBct8bqJm" : {
      "assignedTo" : "",
      "distric" : "MIRAFLORES",
      "id" : 95299,
      "phone" : "999955585",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_N27PmM2B9kBTLgJZr" : {
      "assignedTo" : "",
      "distric" : "MIRAFLORES",
      "id" : 506826,
      "phone" : "983579387",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_N2GiHOSIMJcwLPQ77" : {
      "assignedTo" : "",
      "distric" : "MIRAFLORES",
      "id" : 256244,
      "phone" : "999046675",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_N2jdLp3ouB7oVvVW7" : {
      "assignedTo" : "",
      "distric" : "MIRAFLORES",
      "id" : 460044,
      "phone" : "996379287",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_N3J2YrQ_7vIYFTVvD" : {
      "assignedTo" : "",
      "distric" : "MIRAFLORES",
      "id" : 467775,
      "phone" : "999002859",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_N3nWEPM9TIMQG0lj2" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 720411,
      "phone" : "999403058",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_N44fTLs66OT8hyRhC" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 927538,
      "phone" : "988111198",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_N490S5pR0zAkwrKi7" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 611198,
      "phone" : "988111198",
      "propertyType" : 1,
      "stateProperty" : 1
    },
    "-M_N4VKEBxWLCqTdlhtr" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 85694,
      "phone" : "989003036",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_N5ZZCJiSXgiSUt8Ly" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 383966,
      "phone" : "991645407",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_N5ggz4ZTvADRq-7I7" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 84107,
      "phone" : "963779076",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_N5x8N65qAQQNp-tC0" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 66345,
      "phone" : "998765474",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_N65z_nQETWt2ixPqj" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 3933,
      "phone" : "949998168",
      "propertyType" : 2,
      "stateProperty" : 1
    },
    "-M_N6nD0jcgNe-aJrwxI" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 945448,
      "phone" : "949998168",
      "propertyType" : 2,
      "stateProperty" : 1
    },
    "-M_N6qir2CQrOqraBWMU" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 444230,
      "phone" : "949998168",
      "propertyType" : 2,
      "stateProperty" : 1
    },
    "-M_N7EqHXcOZPoO8hNM5" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 627527,
      "phone" : "996188308",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_N7NXTUZixrv41c5vJ" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 686522,
      "phone" : "983529259",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_N7exypdjyMh1hA77V" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 822124,
      "phone" : "995235534",
      "propertyType" : 1,
      "stateProperty" : 1
    },
    "-M_N7pB6wCHVX_13Btt5" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 579522,
      "phone" : "965421164",
      "propertyType" : 2,
      "stateProperty" : 1
    },
    "-M_N7z8rHMaEdmpICIV3" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 83615,
      "phone" : "945946029",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_N8LhWuqA3DAzizhYb" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 228417,
      "phone" : "946126462",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_N8WmQtFrDAULSFP9G" : {
      "assignedTo" : "",
      "distric" : "SANTIAGO DE SURCO",
      "id" : 963153,
      "phone" : "997552960",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_N8xPF5H4eUVWECZh4" : {
      "assignedTo" : "",
      "distric" : "SURQUILLO",
      "id" : 702366,
      "phone" : "997907800",
      "propertyType" : 2,
      "stateProperty" : 1
    },
    "-M_N9VwdXDUMuUas5JzN" : {
      "assignedTo" : "",
      "distric" : "SURQUILLO",
      "id" : 623380,
      "phone" : "977533653",
      "propertyType" : 2,
      "stateProperty" : 1
    },
    "-M_N9hzffLp6vUT8lV7s" : {
      "assignedTo" : "",
      "distric" : "SURQUILLO",
      "id" : 285470,
      "phone" : "999983528",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_N9nVfBdKurfD-FDS0" : {
      "assignedTo" : "",
      "distric" : "SURQUILLO",
      "id" : 320478,
      "phone" : "987255969",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_N9tlDNDhnWlQE5IGB" : {
      "assignedTo" : "",
      "distric" : "SURQUILLO",
      "id" : 380489,
      "phone" : "994600064",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_N9zleLKqnSQk34D44" : {
      "assignedTo" : "",
      "distric" : "SURQUILLO",
      "id" : 103873,
      "phone" : "991193338",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_NA5__DB7zCsQTs9Cm" : {
      "assignedTo" : "",
      "distric" : "SURQUILLO",
      "id" : 491442,
      "phone" : "998516935",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_NAAbdSTr9XV9fuSL3" : {
      "assignedTo" : "",
      "distric" : "SURQUILLO",
      "id" : 533922,
      "phone" : "986634263",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_NAHT66_niZmARLalV" : {
      "assignedTo" : "",
      "distric" : "SAN BORJA",
      "id" : 884979,
      "phone" : "943566622",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_NAXKtiTW0sdoG-qlS" : {
      "assignedTo" : "",
      "distric" : "SAN BORJA",
      "id" : 454135,
      "phone" : "994135860",
      "propertyType" : 2,
      "stateProperty" : 1
    },
    "-M_NAklay79L-8IcnVry" : {
      "assignedTo" : "",
      "distric" : "SAN BORJA",
      "id" : 652266,
      "phone" : "993578796",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_NAt_LFZNjpqOjiKwm" : {
      "assignedTo" : "",
      "distric" : "SAN BORJA",
      "id" : 619276,
      "phone" : "945179330",
      "propertyType" : 2,
      "stateProperty" : 1
    },
    "-M_NB-qJsCXX_3J9mDPJ" : {
      "assignedTo" : "",
      "distric" : "LA MOLINA",
      "id" : 500676,
      "phone" : "998307402",
      "propertyType" : 2,
      "stateProperty" : 1
    },
    "-M_NB4u3KmCPoD8JAAj9" : {
      "assignedTo" : "",
      "distric" : "LA MOLINA",
      "id" : 437637,
      "phone" : "653688607",
      "propertyType" : 3,
      "stateProperty" : 1
    },
    "-M_NBMsRQ-cMSFukQBEF" : {
      "assignedTo" : "",
      "distric" : "LA MOLINA",
      "id" : 204679,
      "phone" : "986670935",
      "propertyType" : 2,
      "stateProperty" : 1
    },
    "-M_NBTQehDFfD3j9_lhB" : {
      "assignedTo" : "",
      "distric" : "LA MOLINA",
      "id" : 538680,
      "keyBD" : "IDPROP-M_NBTQehDFfD3j9_lhB",
      "phone" : "975189955",
      "propertyType" : 2,
      "stateByCall" : 0,
      "stateProperty" : 1
    }
  }
}
